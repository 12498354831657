// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const API_URLS = {
  prod: 'https://partakeapi.com',
  demo: 'https://demo.partakeapi.com',
  local: 'http://localhost:8080'
};

export const SOCKET_URLS = {
  prod: 'https://socket.partakeapi.com',
  demo: 'https://demo.socket.partakeapi.com',
  local: 'https://demo.socket.partakeapi.com',
};

export const STATS_URLS = {
  prod: 'https://stats.partakeapi.com',
  demo: 'https://staging.stats.partakeapi.com',
  local: 'https://localhost:3000',
};

export const PAY_PAY_KEYS = {
  prod: 'AWYj5FCCZtB2Wk_bOwKgF-sZFTsEFoiTRXFmoXTb4alX_pTA5xVJZQGVJvfCXWFcC0hc3sZOunI9U7vS',
  demo: 'AU-LD2MebRrTaGm9hbWnRV8VWzwtVXkmGJyGw48lPmWu11cAHsN33EgZLpLcnMsVzMMDaiLX4uj-ylcI',
  local: 'AU-LD2MebRrTaGm9hbWnRV8VWzwtVXkmGJyGw48lPmWu11cAHsN33EgZLpLcnMsVzMMDaiLX4uj-ylcI',
};

export const STRIPE_KEYS = {
  prod: 'pk_live_QRYkRf0P7pfqMjn5dcrUJvaF',
  demo: 'pk_test_we0TWA247otFa2At2LgjKfdG',
  local: 'pk_test_we0TWA247otFa2At2LgjKfdG',
};

export const APPLITUDE_KEYS = {
  prod: '22e4c5e9a1e9d14368c60bf1d8475f55',
  demo: '1f702f6db14c3eddf22655d15ea826ab',
  local: '1f702f6db14c3eddf22655d15ea826ab',
};

export const REALM_APP_IDS = {
  prod: '',
  demo: 'partake-demo-web-hbzox',
  local: 'partake-demo-web-hbzox',
  dev: 'partake-demo-web-hbzox'
};

export const environment = {
  production: false,

  /**
   * NEW FORMAT
   */
  urls: {
    domains: {
      api: API_URLS,
      socket: SOCKET_URLS,
      stats: STATS_URLS,
    },
    versions: {
      v1: 'api',
      v2: 'v2'
    }
  },
  stripePK: STRIPE_KEYS,
  paypalKey: PAY_PAY_KEYS,
  realmAppId: REALM_APP_IDS,
  realmDbName: 'partake',
  FB_APP_ID: '190320829031320',
  FB_APP_NAME: 'Partake',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
